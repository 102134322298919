/**
 * 获取 url 参数 问号后的参数
 * 如：xx.com?a=1&b=2
 * */
 export function GetQueryString(name) {
  var result = location.href.match(new RegExp("[\?\&]" + name + "=([^\&]+)", "i"));
  if (result == null || result.length < 1) {
      return "";
  }
  return result[1];
}