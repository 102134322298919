<template>
  <div>
    <div class="menu-box">
      <div class="pl15">
        <i class="iconfont icon-iconshouye3x" @click="goPage()"></i>
        <div class="topNav">
          <div class="center_logo">
            <img src="https://wemeet-1302608036.cos.ap-shanghai.myqcloud.com/ams-live21/images/auto-logo.png"/>
            <div class="midcourt"></div>
            <img src="https://wemeet-1302608036.cos.ap-shanghai.myqcloud.com/ams-live21/images/amsLlive-logo.png"/>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'topbar',
  data() {
    return {
      
    }
  },
  created() {
    
  },
  mounted() {
    
  },
  methods: {
    goPage() {
      window.history.go(-1);
    }
  },
}
</script>

<style lang="less" scoped>
  .menu-box{
    height: 44px;
    line-height: 44px;
    position: fixed;
    background: #fff;
    z-index: 11;
    // padding: 0 15px;
    animation: scrolls-data-v-84158e94 .5s forwards cubic-bezier(.075,.82,.165,1);
    width: 100%;
    top: 0;
    left: 0;
    .icon-iconshouye3x{
      float: left;
      font-size: .46rem;
    }
    .pl15{
      padding: 0 15px;
      display: flex;
    }
    .topNav{
      width: 210px;
      margin: 15px auto;
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 20px;
      color: #576066;
      font-size: 18px;
      .center_logo{
        width: 210px;
        margin: 15px auto;
        display: flex;
        align-items: center;
        justify-content: space-between;
        img{
          width: 50%;
          margin: auto;
          float: left;
        }
        .midcourt{
          width: 1px;
          background: #f1f1f1;
          height: 16px;
          margin: 0 10px 5px;
        }
      }
    }
  }
</style>