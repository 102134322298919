<template>
  <div>
    <topbar></topbar>
    <!-- 登录框 -->
    <div class="login ">
      <div class="loginBox">
        <div class="tab">
          <!-- <div @click="nowCheck=0" class="tabItem" :class="{'check cED3B44': nowCheck == 0}">{{ $t('lang.AccountLogin') }}</div> -->
          <div @click="nowCheck=1" class="tabItem" :class="{'check cED3B44': nowCheck == 1}">{{ $t('lang.moblieLogin') }}</div>
          <!-- <div @click="nowCheck=2" class="tabItem" :class="{'check cED3B44': nowCheck == 2}">{{ $t('lang.emailLogin') }}</div> -->
        </div>

        <div class="form">
          <p class="p_tips">仅限账号已绑定<span>ams-live</span>小程序用户登录使用，如未绑定，请前往PC或手机浏览器登录后，至个人中心-账号安全页，进行<span>ams-live</span>小程序绑定。</p>
          <el-form :model="ruleForm" :rules="rules" ref="ruleForm" class="demo-ruleForm" v-show="nowCheck == 0">
            <el-form-item prop="name">
              <el-input v-model="ruleForm.name" :placeholder="$t('lang.enterEmail')"></el-input>
            </el-form-item>
            <el-form-item prop="password">
              <el-input v-model="ruleForm.pass" type='password' :placeholder="$t('lang.Password')"></el-input>
            </el-form-item>
          </el-form>

          <el-form :model="ruleForm_moblie" :rules="rules_moblie" ref="ruleForm_moblie" class="ruleForm" v-if="nowCheck===1 || nowCheck===2">
            <el-form-item class="labeltitle" prop="moblie" v-if="nowCheck===1">
              <div class="moblie-box">
                <div class="area" @click.stop="showArea=true">
                  +{{selectArea.areaCode}}
                  <i class="iconfont icondianji c666"></i>
                  <transition name="el-zoom-in-top">
                    <ul class="area-list" v-if="showArea">
                      <li @click.stop="selectArea=item;showArea=false" v-for="(item,i) in country_list" :key="i">{{item.name}} +{{item.areaCode}}</li>
                    </ul>
                  </transition>
                </div>
                <el-input :placeholder="$t('lang.enterMoblie')" v-model="ruleForm_moblie.moblie"></el-input>
              </div>
            </el-form-item>
            <el-form-item prop="email" v-if="nowCheck===2">
              <el-input v-model="ruleForm_moblie.email" :placeholder="$t('lang.enterEmail1')"></el-input>
            </el-form-item>
            <el-form-item class="labeltitle" prop="code">
              <el-input :placeholder="nowCheck == 1 ? $t('lang.SMSVerificationCode') : $t('lang.EmailVerificationCode')" maxlength="6" v-model="ruleForm_moblie.code"></el-input>
              <span class="yzm font16 cED3B44" @click="sendCode(nowCheck)"> {{60 > num  ? num : $t('lang.Send')}}</span>
            </el-form-item>
          </el-form>
          <div class="loginBtn" v-loading="isSubmit" element-loading-spinner="el-icon-loading" @click="submitForm(nowCheck==0 ?'ruleForm' :'ruleForm_moblie')">{{$t('lang.SignIn')}}</div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import { GetQueryString } from '@/utils/utils.js'
import { bgData } from '@/utils/data.js'
import topbar from '../components/topbar.vue'
export default {
  components: {
    topbar,
  },
  name: 'login',
  data() {
    var validateMolie = (rule, value, callback) => {
      if (!value || value.length == 0) {
        callback(new Error(this.$t('lang.PleaseEnterMobileNumber')))
      } else {
        callback()
      }
    }
    var validateCode = (rule, value, callback) => {
      let text = this.nowCheck == 1 ? this.$t('lang.PleaseEnterMobileCode') : this.$t('lang.PleaseEnterEmailCode')
      if (!value || value.length == 0) {
        callback(new Error(text))
      } else {
        callback()
      }
    }

    return {
      urlParams: {
        scope: '',
        response_type: '',
        redirect_uri: '',
        state: '',
        client_id: '',
      },
      url: '',
      ruleForm: {},
      nowCheck: 1,
      sendFlag: true,
      isSubmit: false,
      selectArea: {
        areaCode: '86',
      },
      num: 60,
      showArea: false,
      country_list: [],
      rules: {
        name: { required: true, message: this.$t('lang.Pleaseenteremail'), trigger: 'blur' },
        password: { required: true, message: this.$t('lang.Pleaseenterpassword'), trigger: 'blur' },
      },
      ruleForm_moblie: {
        moblie: '',
        email: '',
        code: '',
      },
      rules_moblie: {
        moblie: [{ validator: validateMolie, trigger: 'blur' }],
        email: [
          { required: true, message: this.$t('lang.PleaseEnter') },
          { type: 'email', message: this.$t('lang.PleaseEnterTheCorrectEmailAddress'), trigger: ['blur', 'change'] },
        ],
        code: [{ validator: validateCode, trigger: 'blur' }],
      },
    }
  },
  watch: {
    nowCheck(v) {
      // this.errorTip.email = ''
      // this.errorTip.password = ''
      // this.errorTip_moblie.moblie = ''
      // this.errorTip_moblie.email = ''
      // this.errorTip_moblie.code = ''
      this.ruleForm.name = ''
      this.ruleForm.pass = ''
      this.ruleForm_moblie.moblie = ''
      this.ruleForm_moblie.email = ''
      this.ruleForm_moblie.code = ''
      if (this.$refs['ruleForm_moblie'] !== undefined) {
        this.$refs['ruleForm_moblie'].clearValidate()
      }
    },
  },
  created() {
    document.body.className = 'login_regist_bg3'
    this.urlParams = {
      scope: GetQueryString('scope') || '',
      response_type: GetQueryString('response_type') || '',
      redirect_uri: GetQueryString('redirect_uri') || '',
      state: GetQueryString('state') || '',
      client_id: GetQueryString('client_id') || '',
    }
    this.url = `scope=${this.urlParams.scope}&response_type=${this.urlParams.response_type}&redirect_uri=${this.urlParams.redirect_uri}&state=${this.urlParams.state}&client_id=${this.urlParams.client_id}`
    this.setLink()
  },
  methods: { 
    setLink() {
      var link = document.querySelector("link[rel*='icon']") || document.createElement('link')
      link.type = 'image/x-icon'
      link.rel = 'shortcut icon'
      link.href = 'https://infosalons.oss-cn-shanghai.aliyuncs.com/vscenter/uploads/images/gsos/favicon32.png'
      document.getElementsByTagName('head')[0].appendChild(link)
      document.title = "ams-live"
    },
    //发送验证码
    codeNum() {
      this.num--
      if (this.num <= 0) {
        this.num = 60
        this.sendFlag = true
        return
      }
      setTimeout(this.codeNum, 1000)
    },
    sendCode(tabIndex) {
      if (tabIndex == 1) {
        this.$refs['ruleForm_moblie'].validateField('moblie', (res) => {
          if (!this.sendFlag || res) {
            return
          }
          this.getCodeApi(tabIndex)
        })
      } else {
        this.$refs['ruleForm_moblie'].validateField('email', (res) => {
          if (!this.sendFlag || res) {
            return
          }
          this.getCodeApi(tabIndex)
        })
      }
    },
    getCodeApi(tabIndex) {
      if (tabIndex == 1) {
        // 手机验证码
        let params = `phone=${this.ruleForm_moblie.moblie}`
        this.$http
          .get('/login/sendPhoneCaptcha?' + params)
          .then((res) => {
            console.log(res)
            if (res.code == 200) {
              this.sendFlag = false
              this.codeNum()
            } else {
              this.$message.error(res.data.message)
              this.sendFlag = true
            }
          })
          .catch((err) => {
            this.sendFlag = true
            console.log(err)
          })
      } else {
        // 邮箱验证码
        let params = `email=${this.ruleForm_moblie.email}`
        this.$http.get('/login/sendMailCaptcha?' + params).then((res)=>{
          console.log(res)
          if(res.code == 200) {
            this.sendFlag = false
            this.codeNum()
          } else {
            this.$message.error(res.data.message);
            this.sendFlag = true
          }
        }).catch((err)=>{
          this.sendFlag = true
          console.log(err)
        })
      }
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (this.nowCheck == 0) {
            this.sign()
          } else if (this.nowCheck == 1) {
            this.sign_phone()
          } else if (this.nowCheck == 2) {
            this.sign_email()
          }
        } else {
          console.log('submit error')
          return false
        }
      })
    },
    // 账号密码登录
    sign() {
      if (this.isSubmit) return
      this.isSubmit = true
      this.$http
        .get(`oauth2/authorize/code?type=PASSWORD&username=${this.ruleForm.name}&password=${ruleForm.pass}&` + this.url)
        .then((res) => {
          if (res.code == 200) {
            let data = res.data
            let redirectUrl = data.redirectUrl + '?code=' + data.code + '&state=' + this.urlParams.state
            window.location.href = redirectUrl
          } else {
            this.$message.error(res.data.message)
          }
          this.isSubmit = false
        })
        .catch((err) => {
          this.isSubmit = false
          console.log(err)
        })
    },
    // 手机验证码登录
    sign_phone() {
      if (this.isSubmit) return
      this.isSubmit = true
      this.$http
        .get(`oauth2/authorize/code?type=PHONE&appKey=WX_MINI_AMS_2021&phone=${this.ruleForm_moblie.moblie}&captcha=${this.ruleForm_moblie.code}&` + this.url)
        .then((res) => {
          console.log(res)
          if (res.code == 200) {
            let data = res.data
            let redirectUrl = data.redirectUrl + '?code=' + data.code + '&state=' + this.urlParams.state
            window.location.href = redirectUrl
          } else {
            this.$message.error(res.data.message)
          }
          this.isSubmit = false
        })
        .catch((err) => {
          this.isSubmit = false
          console.log(err)
        })
    },
    // email验证码登录
    sign_email() {
      if(this.isSubmit) return
      this.isSubmit = true
      this.$http.get(`oauth2/authorize/code?type=EMAIL&appKey=WX_MINI_AMS_2021&email=${this.ruleForm_moblie.email}&emailCaptcha=${this.ruleForm_moblie.code}&` + this.url)
      .then((res)=>{ 
        console.log(res)
        if(res.code == 200) {
          let data = res.data
          let redirectUrl = data.redirectUrl + "?code=" + data.code + "&state=" + this.urlParams.state
          window.location.href = redirectUrl
        } else {
          this.$message.error(res.data.message);
        }
        this.isSubmit = false
      }).catch((err)=>{
        this.isSubmit = false
        console.log(err)
      })
    }
  },
}
</script>


<style lang="less" scoped>
.login {
  width: 100%;
  // height: 100vh;
  position: fixed;
  z-index: 2;
  overflow: hidden;
  width: 100%;
  height: calc(100vh);
  padding: 0;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 100%;
  .loginBox {
    position: absolute;
    // top: 0;
    // left: 0;
    // right: 0;
    // bottom: 0;
    margin: auto auto;
    width: 640px;
    // height: 400px;
    background: #f7f7f7;
    padding: 30px 40px;
    box-sizing: border-box;
    overflow: hidden;
    top: 50%;
    left: 50%;
    position: absolute;
    transform: translate(-50%, -50%);
    .p_tips{
      margin-bottom: .40rem;
      text-align: left;
      color: #333;
      span{
        color: #ed3b44;
        font-weight: 600;
      }
    }
    .tab {
      display: flex;
      .tabItem {
        margin-right: 40px;
        cursor: pointer;
        font-size: 20px;
        font-family: SourceHanSansCN-Normal, SourceHanSansCN;
        font-weight: 400;
        // color: #333333;
        &:last-child {
          margin-right: 0;
        }

        &.check {
          position: relative;
          &::after {
            content: '';
            position: absolute;
            bottom: -15px;
            left: 50%;
            transform: translateX(-50%);
            width: 60%;
            height: 4px;
            background: #ed3b44;
          }
        }
      }
    }
    .form {
      margin-top: 50px;
      .loginBtn {
        width: 100%;
        height: 60px;
        line-height: 60px;
        text-align: center;
        background: #0aa0eb;
        font-size: 20px;
        font-weight: 500;
        color: #ffffff;
        margin-top: 40px;
      }
    }
    .moblie-box {
      display: flex;
      background: #fff;
      .area {
        margin: 0 20px;
        line-height: 60px;
        cursor: pointer;
        position: relative;
        z-index: 2;
        i {
          margin-left: 10px;
        }
        &::after {
          content: '';
          display: block;
          width: 1px;
          height: 20px;
          background: #ebebeb;
          position: absolute;
          right: -20px;
          top: 20px;
        }
        .area-list {
          width: 320px;
          position: absolute;
          max-height: 300px;
          text-align: left;
          overflow-y: scroll;
          background: #fff;
          top: 60px;
          left: -20px;
          li {
            padding-left: 20px;
            &:hover {
              background: #efefef;
            }
          }
        }
      }
      /deep/ .el-input {
        flex: 1;
      }
    }
    .yzm {
      position: absolute;
      right: 20px;
      top: 10px;
      cursor: pointer;
    }
  }
}
@media screen and (max-width: 767px) {
  .login {
    .loginBox {
      width: 94%;
      padding: 0 0.28rem;
      .tab {
        .tabItem {
          margin-right: 0.28rem;
          padding: 0.37rem 0 0.17rem;
          font-size: 0.3rem;
          &.check {
            position: relative;
            &::after {
              content: '';
              position: absolute;
              bottom: -0.15rem;
              left: 50%;
              transform: translateX(-50%);
              width: 60%;
              height: 0.04rem;
              background: #ed3b44;
            }
          }
        }
      }
      .form {
        overflow: hidden;
        margin-top: 0.5rem;
        .el-input {
          height: 0.9rem;
          line-height: 0.9rem;
          font-size: 0.28rem;
        }
        .input::-webkit-input-placeholder {
          font-size: 0.26rem;
        }
        .loginBtn {
          height: 0.9rem;
          line-height: 0.9rem;
          font-size: 0.36rem;
          margin: 0.6rem 0;
        }
      }
      .moblie-box {
        .area {
          margin: 0 0.2rem;
          line-height: 0.9rem;
          &::after {
            right: -0.2rem;
            top: 0.24rem;
          }
          i {
            margin-left: 0.1rem;
          }
        }
      }
      .yzm {
        top: 0.1rem;
      }
    }
  }
}
</style>


<style lang='less'>
.form {
  .el-form-item__content {
    height: 60px;
  }
  .el-input {
    input {
      border: none;
      height: 60px;
      font-size: 16px;
      &::placeholder {
        color: #999999;
        font-size: 16px;
      }
    }
  }
  .el-input__inner {
    height: 60px;
  }
}
@media screen and (max-width: 767px) {
  .form {
    .el-form-item__content {
      height: 0.9rem;
    }
    .el-input {
      input {
        line-height: 0.9rem;
        height: 0.9rem;
        font-size: 0.28rem;
      }
    }
    .el-input__inner {
      height: 0.9rem;
      font-size: 0.28rem;
    }
  }
}
</style>